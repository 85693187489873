const GWYDIR_GROUP_PIPEDRIVE_OWNER_ID = 20818960;


const requestData = async (url, data) =>{
	const response = await fetch("/pipedrive-send" + url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(data),
	});
	const res = await response.json();
	if (res.success && res.data) {
		return res.data;
	}
	return null;
};


const postPerson = async (fullname, phone, email) => {
	const personRequest = {
		name: fullname,
		owner_id: GWYDIR_GROUP_PIPEDRIVE_OWNER_ID,
		email: [
			{
				value: email,
				primary: "true",
				label: "main",
			},
		],
		phone: [
			{
				value: phone,
				primary: "true",
				label: "phone",
			},
		],
		visible_to: "3",
		add_time: new Date(),
	};

	return await requestData("/persons", personRequest);
};

const postLead = async (person, lead_title) => {
	let title_value = `web-form-enquiry - ${person.name}`;
	if (lead_title) {
		title_value = `${lead_title} - ${person.name}`;
	}
	const leadRequest = {
		title: title_value,
		person_id: person.id,
		owner_id: GWYDIR_GROUP_PIPEDRIVE_OWNER_ID,
		visible_to: "3",
	};
	return await requestData("/leads", leadRequest);
};

const postNotes = async (content, lead) => {
	const notesRequest = {
		content,
		lead_id: lead.id,
		pinned_to_lead_flag: 1,
	};
	return await requestData("/notes", notesRequest);
};

const submitForm = async (
	fullname,
	phone,
	email,
	message,
	product,
	lead_title
) => {
	try {
		const person = await postPerson(fullname, phone, email);
		if (!person) {
			console.log("Something went wrong during person request");
			return;
		}
		const lead = await postLead(person, lead_title);
		if (!lead) {
			console.log("Something went wrong during lead request");
			return;
		}
		if (message) {
			const messageRes = await postNotes(`Message: ${message}`, lead);
			if (!messageRes) {
				console.log("Something went wrong during notes request (Message)");
			}
		}
		if (product) {
			const productRes = await postNotes(`Interested in: ${product}`, lead);
			if (!productRes) {
				console.log("Something went wrong during notes request (Interested Product)");
			}
		}
	} catch (error) {
		console.error("Error submitting form:", error);
	}
};

export const pipedriveFormSubmit = (form) => {

	const submit_btn = form.querySelector(":scope #submit");


	if (submit_btn){
		submit_btn.addEventListener("click", async (e) => {
			let isValid = true;
			for (let element of form.elements) {
				// Check if the element is an input and is invalid
				if (element instanceof HTMLInputElement && !element.checkValidity()) {
					isValid = false;
					// Report the validation message
					element.reportValidity();
					// Optionally, you can break after finding the first invalid input
					// break;
				}
			}
			if (isValid){
				const first_name = form.querySelector(":scope #first_name").value;
				const last_name = form.querySelector(":scope #last_name").value;
				const phone = form.querySelector(":scope #phone").value;
				const email = form.querySelector(":scope #email").value;

				const product = form.querySelector(":scope #product").value;
				let message = null;
				if(form.querySelector(":scope #message")){
					message = form.querySelector(":scope #message").value;
				}

				const lead_title_el =  form.querySelector(":scope #lead_title");
				let lead_title = null;
				if (lead_title_el){
					lead_title = lead_title_el.value;
				}
                    
				submit_btn.innerHTML = "Sending";
				submit_btn.classList.add("sending");

				await submitForm(first_name + " " + last_name, phone, email, message, product, lead_title);
				const url = new URL(window.location);

				// Set or update the URL parameter
				url.searchParams.set("form_emailed", "true");
				window.location.href = url + "#form-result";
				form.submit();
			}
			

			e.preventDefault();
			e.stopPropagation();

		});
	}
	
};
